 /* eslint-disable */
import React from 'react'
import { graphql, Link } from 'gatsby'


import Img from 'gatsby-image'

import SEO from "../components/seo"
import Header from "../components/header"

import Footer from "../components/footer"



export default ({ data, pageContext: { node, locale, pagePath } }) => {

    // let pageData = data.pageData.edges[0].node

    let globalData = data.globalData.edges[0].node
    let seriesPages = data.seriesPages

    

    let page_title_local = globalData.frontmatter[locale + "_door_series"] + " " + node.seriesName + " | " + globalData.frontmatter[locale + "_page_title_wooden_doors_prefix"] + " | " + globalData.frontmatter[locale + "_page_title_global_prefix"] + " " + globalData.frontmatter[locale + "_page_title_since_2001_prefix"]  
    let page_meta_description = globalData.frontmatter[locale + "_door_series"] + " " + node.seriesName

    let seriesName = node.seriesName


    return (
        <>


            <SEO 
                pathname={pagePath}
                title={page_title_local}
                description={page_meta_description}

            />

            <Header 
                locale={locale}
                pagePath={pagePath}
             />



            {/**
                UI
             */}

             <div className="container">
                <div className="row">
                    {seriesPages.edges.sort((a, b) => {return(a.node.frontmatter.show_order - b.node.frontmatter.show_order)}).map(({ node }) => (
                        <>                        
                            <div className="col-12 col-sm-4 col-md-3 catalogueDoorCol">
                                <h4>{`${seriesName} ${node.frontmatter.slug.split("/")[1]}`}</h4>
                                    <Link key={locale + '/catalogue/' + node.frontmatter.slug} to={`${locale === "he" ? '' : locale}/catalogue/${node.frontmatter.slug}`}>
                                        <Img alt={page_meta_description} fixed={node.frontmatter.img_a.childImageSharp.fixed} />
                                    </Link>
                            </div>
                        </>
                    ))}


                </div>
            </div>





            <Footer 
                locale={locale}
                pagePath={pagePath}
             />
        </>
    
    )
}




export const seriesQuery = graphql`
query SeriesQuery($doorSeries: String) {
        seriesPages:
        allMarkdownRemark(filter: {frontmatter: {series: {eq: $doorSeries}}}) {
            edges {
                node {
                    frontmatter {
                        series
                        slug
                        show_order
                        img_a {
                            childImageSharp {
                                fixed(width: 172) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                }
            }
        }
        globalData: 
            allMarkdownRemark(filter: {frontmatter: {type: {eq: "global_data"}}}) {
                edges {
                    node {
                        id
                        frontmatter {
                            he_page_title_global_prefix
                            he_page_title_since_2001_prefix
                            he_page_title_wooden_doors_prefix
                            he_door_series
                            fr_page_title_global_prefix
                            fr_page_title_since_2001_prefix
                            fr_page_title_wooden_doors_prefix
                            fr_door_series
                            en_page_title_global_prefix
                            en_page_title_since_2001_prefix
                            en_page_title_wooden_doors_prefix
                            en_door_series
                            pr_page_title_global_prefix
                            pr_page_title_since_2001_prefix
                            pr_page_title_wooden_doors_prefix
                            pr_door_series
                            es_page_title_global_prefix
                            es_page_title_since_2001_prefix
                            es_page_title_wooden_doors_prefix
                            es_door_series
                            ru_page_title_global_prefix
                            ru_page_title_since_2001_prefix
                            ru_page_title_wooden_doors_prefix
                            ru_door_series
                            he_back_to_catalugue
                            ru_back_to_catalugue
                            en_back_to_catalugue
                            fr_back_to_catalugue
                            es_back_to_catalugue
                            pr_back_to_catalugue
                        }
                    }
                }
            }
    
  }`